/* styles.css */
.form-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 8px;
}

input,
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

label input {
  margin-right: 8px;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

/* Media query for screens smaller than 600px */
@media (max-width: 500px) {
  .form-container {
    padding: 50px;
    width: 60%;
  }
}
